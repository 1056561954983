/* Members Section */
.members-section {
  font-family: 'Raleway', sans-serif;
  padding: 50px;
  background-image: url("../public/texture25.jpg");
  text-align: center;
}

.members-section h2 {
  font-size: 2.5em;
  margin-bottom: 30px;
}

/* Individual Member Styling */
.member {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  text-align: left;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
}

.member-info {
  width: 50%;
  padding-right: 20px;
}

.member-info h3 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.member-info p {
  font-family: "Nanum Gothic Coding", monospace;
  font-size: 1.1em;
  line-height: 1.6;
}

/* Uniform Member Photo */
.member-photo {
  width: 50%;
  text-align: center;
  display: contents;
}

.member-photo img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

/* Mobile View */
@media (max-width: 768px) {
  .member {
    flex-direction: column;
    text-align: center;
  }

  .member-info, .member-photo {
    width: 100%;
    padding: 0;
  }

  .member-photo img {
    margin-top: 20px;
  }
}
