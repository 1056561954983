body {
  margin: 0;
  padding: 0;
  /* font-family: 'Roboto', sans-serif; */
}

/* Hero Section */
.hero-section {
  
  position: relative; /* Ensure the section can position its pseudo-element */
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 0; /* Ensures content stays above the background image */
  gap: 20vw;
}

/* Apply a gap for larger screens */
@media (min-width: 768px) {
  .hero-section {
    gap: 0px; /* Adjust the gap as needed */
  }
}


.hero-section::before {
  content: ""; /* Required for pseudo-elements */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../public/Header1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5; /* Adjust this value to change the opacity */
  z-index: -1; /* Places the background behind the content */
}

.hero-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

/* Image Container */
.hero-image-container {
  perspective: 1000px; /* For the 3D effect */
  width: 35vw;
  max-width: 500px;
  height: auto;
  position: relative;
  aspect-ratio: 1; /* Ensure the container is always a square */
  transform-style: preserve-3d; /* Allows for the 3D flip */
  animation: flip-animation 9s infinite; /* Add flip animation */
  margin-top: 20px;
}

.hero-image {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden; /* Hide the back when flipped */
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
}

/* Front and back positioning */
.hero-image-front {
  transform: rotateY(0deg); /* Front image starts visible */
}

.hero-image-back {
  transform: rotateY(180deg); /* Back image starts hidden initially */
}

/* Keyframes for automatic flipping */
@keyframes flip-animation {
  0% {
    transform: rotateY(0deg); /* Start with front image */
  }
  50% {
    transform: rotateY(180deg); /* Halfway flip to back image */
  }
  100% {
    transform: rotateY(360deg); /* Complete flip back to front image */
  }
}

/* Music player */
.hero-music-player {
  margin-left: 16vw; /* Add margin to the left side of the music player */
}

.music-player-box {
  padding: 20px;
  border-radius: 10px;
}

/* Media Query for mobile view */
@media (max-width: 768px) {

  .hero-content {
    flex-direction: column; /* Switch to column layout on mobile */
  }

  .hero-image-container {
    width: 50vw; /* Adjust container width for mobile */
    height: auto;
    margin-bottom: 20px; /* Add space between the image and the music player */
  }

  .hero-music-player {
    margin-left: 0; /* Remove the left margin on mobile */
  }

  
  @media (max-width: 450px) {

    .hero-content {
      flex-direction: column; /* Switch to column layout on mobile */
    }
  
    .hero-image-container {
      width: 70vw; /* Adjust container width for mobile */
      height: auto;
      margin-bottom: 20px; /* Add space between the image and the music player */
    }
  
    .hero-music-player {
      margin-left: 0; /* Remove the left margin on mobile */
    }
  }
}


