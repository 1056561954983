/* Footer Section */
.footer {
  background-color: #f0f0f0; /* Light grey background to match the rest of the design */
  color: #000; /* Black text for contrast */
  padding: 20px 0;
  text-align: center;
  font-family: 'Arial', sans-serif; /* Clean font */
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.footer-links {
  margin-bottom: 10px;
  display: flex;
  gap: 20px;
}

.footer-links a {
  text-decoration: none;
  color: #000;
  font-weight: 500;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #888; /* Subtle hover effect */
}

.copyright {
  font-size: 0.9rem;
  color: #666; /* Lighter color for the copyright text */
}
