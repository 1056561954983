/* About Section */
.about-section {
  padding: 50px;
  background-color: #f7f7f7;
  text-align: center;
}

.about-content {
  font-family: "Raleway", sans-serif;
  max-width: 800px;
  margin: 0 auto;
}

.about-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.about-section p {
  font-size: 1.1em;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #333;
}

.about-section p strong {
  color: #000;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-section {
    padding: 20px;
  }
}
