/* Music Section */
.music-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  background-color: #fafafa;
  text-align: center;
  color: #333;
  font-family: 'Helvetica Neue', sans-serif;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.music-section h2 {
  font-size: 2.8em;
  margin-bottom: 20px;
  color: #222;
  font-weight: bold;
}

.music-spotify {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.newsletter-button {
  margin-top: 30px;
  padding: 12px 24px;
  font-size: 1em;
  font-weight: 600;
  color: #fff;
  background-color: #1db954; /* Spotify green */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.newsletter-button:hover {
  background-color: #17a749;
}

/* General Modal Overlay */
.overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Container */
.modal {
  background-color: #fff;
  padding: 40px;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  text-align: center;
  font-family: 'Arial', sans-serif;
  position: relative;
  animation: fadeIn 0.3s ease;
}

/* Close Button */
.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #888;
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  transition: color 0.3s;
}

.close-button:hover {
  color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
  .music-section {
    padding: 40px 20px;
  }

  .music-section h2 {
    font-size: 2em;
  }

  .music-spotify {
    width: 100%;
  }

  .modal {
    width: 90%;
    padding: 30px;
  }
}

/* Keyframes for modal animation */
@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.95); }
  to { opacity: 1; transform: scale(1); }
}
