.contact-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
}

.contact-button:hover {
  background-color: #0056b3;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  max-width: 365px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  margin-bottom: 5px;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #0056b3;
}

.close-button {
  margin-top: 15px;
  padding: 8px 16px;
  font-size: 14px;
  background-color: #ccc;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #999;
}

/* General Modal Overlay */
.overlay {
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal Container */
.modal {
  background-color: #fff;
  padding: 40px;
  width: 400px;
  max-width: 90%;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: 'Arial', sans-serif;
}

/* Modal Heading */
.modal h2 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

/* Modal Paragraph Text */
.modal p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 30px;
}

/* Form Styles */
.newsletter-form label {
  display: block;
  font-size: 0.9rem;
  color: #444;
  text-align: left;
  margin-bottom: 5px;
}

.newsletter-form input[type="text"],
.newsletter-form input[type="email"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
  transition: border-color 0.2s;
}

.newsletter-form input[type="text"]:focus,
.newsletter-form input[type="email"]:focus {
  border-color: #555;
  outline: none;
}

/* Submit Button */
.newsletter-form button[type="submit"] {
  background-color: #333;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.newsletter-form button[type="submit"]:hover {
  background-color: #e65c2d;
}

/* Close Button */
.close-button {
  background: none;
  border: none;
  font-size: 1.2rem;
  color: #888;
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  transition: color 0.3s;
}

.close-button:hover {
  color: #333;
}

/* Contact Button */
.newsletter-button {
  background-color: #333;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.newsletter-button:hover {
  background-color: #555;
}

