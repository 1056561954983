
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  font-family: 'Roboto', sans-serif;
}

#boxes * {
  border: 1px solid transparent;
}

#boxes {
  padding-block: 15px;
  margin: none;
  /* display: grid;
  grid-template-columns: max-content max-content;
  grid-template-areas: 
  'A B'
  'A C';
  gap: 32px;
  place-content: center;
  height: 100vh; */
}


#player03 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: auto;
  margin: 10px;
}

#player03 .controls {
  display: flex;
  justify-content: space-around;
}

.player {
  background-color: rgb(1 24 36 / 46%);
  padding: 12px;
  border-radius: 20px;
}

.player img {
  width: 84px;
  height: 84px;
  object-fit: cover;
  border-radius: 10px;
}


.info {
 color: #E1E1E6;
}

.info p {
  opacity: 0.68;
  font-size: 19px;
}

.info-wrapper {
  display: flex;
  align-items: center;
  gap: 30px;
}

.player h1 {
  font-size: 27px;
  color: #E1E1E6;
  padding-bottom: 7px;
}



.controls {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.track {
  width: 100%;
  height: 6px;
  background-color: #e1e1e6;
  border-radius: 10px;
  position: relative;
  margin-top: 20px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #080747;
  width: 0;
  transition: width 0.1s ease-in;
  border-radius: 10px;
}

.track::before {
content: '';
height: 6px;
width: 100%;
display: block;
background: #D9D9D9;
opacity: 0.3;
border-radius: 10px;
position: absolute;
}

.track::after { 
  content: '';
height: 6px;
width: 85%;
display: block;
background: #D9D9D9;
border-radius: 10px;


}

.time {
  opacity: 0.7;
  font-size: 14px;
  color: gainsboro;

  display: flex;
  justify-content: space-between;
  padding-top: 9.6px;
}

@media (max-width: 670px) {
  #boxes {
    display:flex;
    flex-direction: column;

    max-width: 380px;
    margin: none;


    height: auto;
    padding-block: 15px;
  }



}




