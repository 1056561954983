/* Base styles for the navbar */
.navbar {
  font-family: "Raleway", sans-serif;
  /* background-image: url('../public/PageHeader.jpg');
  background-size: cover; /* Makes the image cover the whole element */
  background-position: center; /* Positions the texture at the center */
  background-repeat: no-repeat; 
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
};

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
  margin-left: -38px;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  text-decoration: none;
  color: black;
  font-weight: 500;
  font-size: 1rem;
}

.nav-links a:hover {
  color: #888; /* Subtle hover effect */
}

/* Hamburger menu */
.hamburger {
  display: none; /* Hidden on large screens */
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
}

.bar {
  width: 100%;
  height: 3px;
  background-color: black;
  border-radius: 5px;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide links on small screens */
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    flex-direction: column;
    background-color: #f0f0f0;
    padding: 20px;
    gap: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  .nav-links.open {
    display: flex; /* Show links when menu is open */
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.45);
    
  }

  .hamburger {
    display: flex; /* Show hamburger on small screens */
  }

  .nav-links a {
    color: rgba(255,255, 255, 0.9);
    font-size: 1.2rem;
    padding: 10px 20px;
    text-align: center;
    width: 100%;
  }
}
